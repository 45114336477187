html{
  background-color: #0d3f67;
  color: #ffffff;
}

ul, li{
  list-style: none;
  display: inline;
  margin: 0;
  padding:0;
}


.checkbox, .button{
  height:20px;
  width: 20px;
  border-radius: 10px;
}

.checked{
  opacity: 0.5;
  text-decoration: line-through;
}

input{
  border: 0px;
  text-align: center;
  height: 30px;
  width: 180px;
  font-size: 15px;
}

.row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1vh auto;
  justify-content: space-between;
  align-items: center;
}

.item, .create-item{
  padding: 1vh 1vw;
  border-radius: 10px;
  width: 300px;
  height: 30px;
  font-size: 15px;
}

.create-item{
  margin: 0vh auto 2vh;
  text-align: center;
  border: 0px transparent;
}
.item-dark{
  background-color: #fff;
  color: #696464;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: center;
  margin:0 5px;
}

.align-center{
  text-align: center;
}

.margin-auto-center{
  margin: 0 auto;
}

.grocery-list{
  margin: 2vh auto;
  width: 350px;
  background-color: #6b48ff;
  padding: 1vh 2vw;
  border-radius: 10px;
  border: 2px solid #fff;
}

.button-clear{
  background-color: transparent;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  border: 0;
  padding: 0;
  width: 100%;
}

.trash{
  margin-right: 25px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.checkbox-checked + .slider {
  background-color: #1ee3cf;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1ee3cf;
}

.checkbox-checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
  height: 28px;
}

.slider.round:before {
  border-radius: 50%;
}